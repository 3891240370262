<template>
  <b-card-actions
    ref="formCard"
    no-actions
  >

    <b-row class="m-0 align-items-center">
      <div
        class="wrapper__handle__back cursor-pointer bg-primary"
        @click="$router.go(-1)"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          size="25"
          style="color: white;"
        />
      </div>
      <h3 class="text-black ml-1 mt-50">
        <strong>
          Buat Bundling
        </strong>
      </h3>
    </b-row>
    <div class="mt-[32px] space-y-[32px]">
      <BRow class="items-center">
        <BCol md="3" class="font-[500] text-[#222]">
          Nama Paket Bundling<span class="text-danger">*</span>
        </BCol>
        <BCol md="5">
          <b-input-group>
            <b-form-input
              v-model="bundlingName"
              placeholder="Masukkan nama paket bundling"
              :formatter="formatName"
              @update="checkBundlingName"
              @input="unavailable = false, requiredBundlingName = false"
            />
          </b-input-group>
          <div
            v-if="unavailable"
            class="absolute small text-danger mt-[4px]"
          >
            Nama bundling telah digunakan. Silakan menggunakan nama lain
          </div>
          <div
            v-if="requiredBundlingName"
            class="absolute small text-danger mt-[4px]"
          >
            Nama bundling wajib diisi
          </div>
        </BCol>
      </BRow>
      <div class="flex flex-row items-center">
        <div class="basis-1/4 font-[500] text-[#222]">
          Volume Bundling
        </div>
      </div>
    </div>
    <div class="mt-[24px] space-y-[28px]">
      <BRow class="items-center">
        <BCol md="3" class="font-[400] text-[#626262]">
          Panjang
        </BCol>
        <BCol md="5">
          <b-input-group append="cm">
            <b-form-input
              v-model="length"
              :formatter="formatDimension"
              number
            />
          </b-input-group>
        </BCol>
      </BRow>
      <BRow class="items-center">
        <BCol md="3" class="font-[400] text-[#626262]">
          Lebar
        </BCol>
        <BCol md="5">
          <b-input-group append="cm">
            <b-form-input
              v-model="width"
              :formatter="formatDimension"
              number
            />
          </b-input-group>
        </BCol>
      </BRow>
      <BRow class="items-center">
        <BCol md="3" class="font-[400] text-[#626262]">
          Tinggi
        </BCol>
        <BCol md="5">
          <b-input-group append="cm">
            <b-form-input
              v-model="height"
              :formatter="formatDimension"
              number
            />
          </b-input-group>
        </BCol>
      </BRow>
    </div>
    <div
      v-if="step === 1"
      class="mt-[32px] border rounded-[4px] p-[16px]"
    >
      <div class="space-y-[24px]">
        <div class="basis-1/4 font-[500] text-[#222]">
          Pilih Paket Bundling<span class="text-danger">*</span>
        </div>
        <div class="space-y-[12px]">
          <div class="text-[12px] text-[#222]">
            Produk Utama<span class="text-danger">*</span>
          </div>
          <b-overlay
            variant="light"
            :show="loadingMain"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
          >
            <b-table
              :fields="fieldsMainProduct"
              :items="main_product"
              empty-text="Tidak ada data"
              show-empty
              class="border"
              responsive
            >
              <template #cell(checkbox)="data">
                <b-form-checkbox
                  v-model="data.item.checked"
                  v-b-tooltip.hover.top="'Produk utama tidak bisa dihilangkan dari bundling'"
                  disabled
                />
              </template>
              <template #cell(product_name)="data">
                <div class="flex gap-[16px] items-start">
                  <img
                    :src="data.item.product_image[0] ? data.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                    alt="product"
                    width="52"
                    height="52"
                  >
                  <div class="space-y-[4px]">
                    <div class="font-medium">
                      {{ data.item.product_name }}
                    </div>
                    <div class="text-primary text-[12px]">
                      SKU: {{ data.item.product_sku }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(total)="data">
                <div class="flex gap-[4px] items-center justify-center">
                  <b-button
                    variant="primary"
                    class="w-[24px] h-[24px] !rounded-full p-0"
                    :disabled="data.item.total === 1"
                    @click="data.item.total -= 1"
                  >
                    -
                  </b-button>
                  <div class="w-[60px] px-[10px] py-[3px] rounded-[12px] bg-[#F4F4F4] text-center">
                    {{ data.item.total }}
                  </div>
                  <b-button
                    variant="primary"
                    class="w-[24px] h-[24px] !rounded-full p-0"
                    @click="data.item.total += 1"
                  >
                    +
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-overlay>
        </div>
        <div class="space-y-[12px]">
          <BRow class="justify-between items-center">
            <BCol md="3" cols="5" class="text-[12px] text-[#222]">
              Produk Add On<span class="text-danger">*</span>
            </BCol>
            <BCol md="4" cols="7">
              <b-input-group class="w-100 input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchProduct"
                  placeholder="Cari nama produk"
                  debounce="500"
                  @update="fetchProduct()"
                />
              </b-input-group>
            </BCol>
          </BRow>
          <b-overlay
            variant="light"
            :show="loadingAddon"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
          >
            <b-table
              id="table"
              :fields="fieldsAddonProduct"
              :items="addon_product"
              empty-text="Tidak ada data"
              show-empty
              class="border"
              responsive
            >
              <template #cell(checkbox)="data">
                <b-form-checkbox
                  v-if="data.item.disabledCheck"
                  v-b-tooltip.hover.top="'Kamu hanya dapat menambahkan 9 produk'"
                  :disabled="data.item.disabledCheck"
                />
                <b-form-checkbox
                  v-else
                  v-model="data.item.checked"
                  @change="handleCheckboxChange(data.item)"
                />
              </template>
              <template #cell(product_name)="data">
                <div class="flex gap-[16px] items-start">
                  <img
                    :src="data.item.product_image[0] ? data.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                    alt="product"
                    width="52"
                    height="52"
                  >
                  <div class="space-y-[4px]">
                    <div class="font-medium">
                      {{ data.item.product_name }}
                    </div>
                    <div class="text-primary text-[12px]">
                      SKU: {{ data.item.product_sku }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(total)="data">
                <div class="flex gap-[4px] items-center justify-center">
                  <b-button
                    variant="primary"
                    class="w-[24px] h-[24px] !rounded-full p-0"
                    :disabled="data.item.total === 0"
                    @click="data.item.total -= 1"
                  >
                    -
                  </b-button>
                  <div class="w-[60px] px-[10px] py-[3px] rounded-[12px] bg-[#F4F4F4] text-center">
                    {{ data.item.total }}
                  </div>
                  <b-button
                    variant="primary"
                    class="w-[24px] h-[24px] !rounded-full p-0"
                    :disabled="!data.item.checked"
                    @click="data.item.total += 1"
                  >
                    +
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-overlay>
          <div class="!mt-[24px] text-right">
            <b-button
              :variant="disabledSetDisc() ? 'secondary' : 'primary'"
              size="sm"
              :disabled="disabledSetDisc()"
              @click="step = 2, discount_product = main_product.concat(selected)"
            >
              Atur Diskon
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 2">
      <div
        class="mt-[32px] border rounded-[4px] p-[16px]"
      >
        <div class="space-y-[24px]">
          <div class="basis-1/4 font-[500] text-[#222]">
            Atur Diskon Paket Bundling<span class="text-danger">*</span>
          </div>
          <b-table
            :fields="fieldsDiscountProduct"
            :items="discount_product"
            empty-text="Tidak ada data"
            show-empty
            class="border discount-product-table"
            responsive
          >
            <template #cell(product_name)="data">
              <div class="flex gap-[16px] items-start">
                <img
                  :src="data.item.product_image[0] ? data.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                  alt="product"
                  width="52"
                  height="52"
                >
                <div class="space-y-[4px]">
                  <div class="font-medium">
                    {{ data.item.product_name }}
                  </div>
                  <div class="text-primary text-[12px]">
                    SKU: {{ data.item.product_sku }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(total)="data">
              <div class="">
                {{ data.item.total }}
              </div>
            </template>
            <template #cell(price)="data">
              <div class="">
                {{ data.item.product_price * data.item.total | rupiah }}
              </div>
            </template>
            <template #cell(discount)="data">
              <div v-if="disabledDiscount">
                {{ data.item.discount }}%
              </div>
              <div v-else>
                <b-input-group append="%">
                  <b-form-input
                    v-model="data.item.discount"
                    number
                    :formatter="formatDiscount"
                  />
                </b-input-group>
              </div>
            </template>
            <template #cell(final_price)="data">
              <div class="">
                {{ data.item.final_price = calculateDiscount(data.item.product_price, data.item.discount, data.item.total) | rupiah }}
              </div>
            </template>
          </b-table>
          <div
            v-if="disabledDiscount"
            class="text-right space-x-[16px]"
          >
            <b-button
              variant="outline-primary"
              size="sm"
              @click="step = 1, discount_product = [], disabledDiscount = false"
            >
              Edit Produk
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              @click="disabledDiscount = false"
            >
              Edit Diskon
            </b-button>
          </div>
          <div
            v-else
            class="text-right space-x-[16px]"
          >
            <b-button
              variant="outline-primary"
              size="sm"
              @click="step = 1, discount_product = []"
            >
              Edit Produk
            </b-button>
            <b-button
              variant="primary"
              size="sm"
              @click="disabledDiscount = true, successSave()"
            >
              Simpan
            </b-button>
          </div>
        </div>
      </div>
      <div class="text-right mt-[32px]">
        <b-button
          :variant="!bundlingName || unavailable || !disabledDiscount ? 'secondary' : 'primary'"
          :disabled="!bundlingName || unavailable || !disabledDiscount"
          @click="createPayload()"
        >
          Submit Bundling
        </b-button>
      </div>
    </div>
  </b-card-actions>
</template>

<script>
import {
  BRow,
  BFormInput,
  BButton,
  BTable,
  BInputGroup,
  BOverlay,
} from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import filters from '@/libs/filters'
import { komshipAxiosIns } from '@/libs/axios'
import { fieldsMainProduct, fieldsAddonProduct, fieldsDiscountProduct } from './config'

export default {
  components: {
    BRow,
    BButton,
    BCardActions,
  },
  filters: { rupiah: filters.rupiah },
  data() {
    return {
      step: 1,

      bundlingName: '',
      unavailable: false,
      requiredBundlingName: false,
      length: '',
      width: '',
      height: '',

      fieldsMainProduct,
      fieldsAddonProduct,
      fieldsDiscountProduct,
      main_product: [],
      addon_product: [],
      discount_product: [],

      disabledDiscount: false,

      selected: [],

      productId: this.$route.params.product_id,
      searchProduct: '',

      loadingMain: false,
      loadingAddon: false,

      limit: 50,
      offset: 0,
      lastData: false,
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.fetchProduct()
  },
  mounted() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('table').offsetHeight && !this.loadingAddon) {
        this.fetchNextProduct()
      }
    }
  },
  methods: {
    fetchProduct() {
      this.offset = 0
      this.loadingMain = this.main_product.length === 0
      this.loadingAddon = true
      const url = `/v1/product/bundling/product-component/${this.productId}`
      const params = `?search=${this.searchProduct}&limit=${this.limit}&offset=${this.offset}`
      komshipAxiosIns.get(`${url}${params}`).then(res => {
        const { data } = res.data

        if (this.main_product.length === 0) {
          const mainProduct = [data.main_product]

          this.main_product = mainProduct.map(item => ({
            ...item,
            checked: true,
            disabledCheck: false,
            total: 1,
            discount: 0,
            final_price: item.product_price,
          }))
        }

        const updated = data.addon_product.map(item => {
          const newItem = { ...item }
          newItem.checked = false
          newItem.disabledCheck = this.selected.length === 9
          newItem.total = 0
          newItem.discount = 0
          newItem.final_price = item.product_price
          return newItem
        })

        if (updated.length < this.limit) {
          this.lastData = true
        } else {
          this.lastData = false
        }
        this.offset = updated.length

        this.selected.forEach(obj => {
          updated[updated.findIndex(item => item.id === obj.id)] = obj
        })

        this.addon_product.forEach(obj => {
          updated[updated.findIndex(item => item.id === obj.id)] = obj
        })

        this.addon_product = updated

        this.loadingMain = false
        this.loadingAddon = false
      })
    },
    fetchNextProduct() {
      if (!this.lastData) {
        this.loadingMain = this.main_product.length === 0
        this.loadingAddon = true
        const url = `/v1/product/bundling/product-component/${this.productId}`
        const params = `?search=${this.searchProduct}&limit=${this.limit}&offset=${this.offset}`
        komshipAxiosIns.get(`${url}${params}`).then(res => {
          const { data } = res.data

          if (this.main_product.length === 0) {
            const mainProduct = [data.main_product]

            this.main_product = mainProduct.map(item => ({
              ...item,
              checked: true,
              disabledCheck: false,
              total: 1,
              discount: 0,
              final_price: item.product_price,
            }))
          }

          const updated = data.addon_product.map(item => {
            const newItem = { ...item }
            newItem.checked = false
            newItem.disabledCheck = this.selected.length === 9
            newItem.total = 0
            newItem.discount = 0
            newItem.final_price = item.product_price
            return newItem
          })

          this.selected.forEach(obj => {
            updated[updated.findIndex(item => item.id === obj.id)] = obj
          })

          this.addon_product.forEach(obj => {
            updated[updated.findIndex(item => item.id === obj.id)] = obj
          })

          this.addon_product.push(...updated)
          this.offset += data.addon_product.length
          if (data.addon_product.length < this.limit) {
            this.lastData = true
          }

          this.loadingMain = false
          this.loadingAddon = false
        })
      }
    },
    formatDiscount(value) {
      const formattedValue = value ? value.replace(/[^\d]/g, '').slice(0, 3) : 0
      return Math.min(formattedValue, 100)
    },
    formatDimension(value) {
      const formattedValue = value ? value.replace(/[^\d]/g, '') : ''
      // Check if the formatted value starts with '0'
      if (formattedValue.startsWith(0)) {
        return formattedValue.substring(1) // Remove the leading '0'
      }
      return formattedValue
    },
    calculateDiscount(price, discount, total) {
      return price * total - (price * total * (discount / 100))
    },
    handleCheckboxChange(item) {
      if (item.checked) {
        // eslint-disable-next-line no-param-reassign
        item.total = 1
        // If the checkbox is checked, add the item to the selected array
        this.selected.push(item)
        this.handleMax9Checked()
      } else {
        // If the checkbox is unchecked, remove the item from the selected array
        const index = this.selected.indexOf(item)
        if (index !== -1) {
          this.selected.splice(index, 1)
          // eslint-disable-next-line no-param-reassign
          item.total = 0
        }
        this.handleMax9Checked()
      }
    },
    handleMax9Checked() {
      if (this.selected.length === 9) {
        this.addon_product.forEach(item => {
          if (!item.checked) {
            // eslint-disable-next-line no-param-reassign
            item.disabledCheck = true
          }
        })
      } else {
        this.addon_product.forEach(item => {
          // eslint-disable-next-line no-param-reassign
          item.disabledCheck = false
        })
      }
    },
    // eslint-disable-next-line func-names
    checkBundlingName: _.debounce(function () {
      if (this.bundlingName !== '') {
        const params = {
          name: this.bundlingName,
        }
        komshipAxiosIns.get('/v1/product/bundling/check-name', { params }).then(res => {
          const { is_available } = res.data.data
          // eslint-disable-next-line camelcase
          this.unavailable = is_available === 1
        })
      } else {
        this.requiredBundlingName = true
      }
    }, 1000),
    formatName(value) {
      if (value.startsWith(' ')) {
        this.checkBundlingName()
        return value.substring(1)
      }
      return value.substring(0, 30)
    },
    disabledSetDisc() {
      if (this.main_product[0].total > 1) {
        return false
      }

      const hasTotalZero = this.selected.some(item => item.total === 0)

      if (hasTotalZero || this.selected.length === 0) {
        return true
      }

      return false
    },
    successSave() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          variant: 'success',
          text: 'Diskon berhasil disimpan',
        },
      })
    },
    createPayload() {
      const bundleComponent = this.discount_product.map((item, index) => ({
        product_id: item.id,
        qty: item.total,
        disc: item.discount,
        main_component: index === 0 ? 1 : 0,
      }))

      const payload = {
        bundle_name: this.bundlingName,
        bundle_volumetric: {
          length: this.length,
          width: this.width,
          height: this.height,
        },
        bundle_component: bundleComponent,
      }
      this.submitBundling(payload)
    },
    submitBundling(payload) {
      komshipAxiosIns.post('/v1/product/bundling', payload).then(res => {
        this.$router.push({
          name: 'data-produk',
        })
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XCircleIcon',
            variant: 'danger',
            text: 'Gagal membuat produk bundling, silakan coba lagi',
          },
        })
      })
    },
  },
}
</script>

<style>
.wrapper__handle__back {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper__handle__back:hover {
  background-color: #F8F8F8;
}

.discount-product-table tbody tr:first-child {
  background-color: #FFECE9 !important;
}
</style>
